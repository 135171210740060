<template>
  <div class="page">
    <main>
      <manage-tab />

      <div class="mb-5">
        <vue-csv-import
          v-model="csvData"
          :map-fields="mapFields"
          :headers="true"
          :autoMatchFields="true"
          :autoMatchIgnoreCase="true"
          :canIgnore="true"
          loadBtnText="読み込む"
        >
        </vue-csv-import>
      </div>

      <div v-if="csvData.length > 0">
        <hr>
        <b-field grouped>
          <b-field label="All">
            <p class="control">
              <span class="button is-static">{{ csvData.length }} レコード</span>
            </p>
          </b-field>
          <b-field label="Start">
            <b-input v-model="startIndex" type="number" />
          </b-field>
          <b-field label="End">
            <b-input v-model="endIndex" type="number" />
          </b-field>
        </b-field>

        <div class="level is-mobile mt-5">
          <div class="level-left">
            <div class="level-item">
              <button class="button is-primary" @click="importData" :class="{ 'is-loading': isProcessing }">インポート</button>
            </div>
            <div class="level-item">
              <label class="checkbox pl-1">
                <input type="checkbox" v-model="override">
                存在する場合は上書きする
              </label>
            </div>
          </div>
          <div class="level-right">
            <div class="level-item" v-if="index < count">
              {{ index }} / {{ count }} ({{ progress }}%)
            </div>
          </div>
        </div>

        <hr>

        <div class="mb-6">
          <p class="has-text-weight-bold">プレビュー:</p>
          <div class="notification">
            <b-table :data="filteredCsvData" :columns="tableColumns" />
          </div>
        </div>

        <div class="mb-5">
          <p class="has-text-weight-bold">RAWデータ:</p>
          <div class="notification">{{ filteredCsvData[0] }}</div>
        </div>
      </div>
    </main>
  </div>
</template>


<script>
import { db } from '@/main'
import { VueCsvImport } from 'vue-csv-import'
import ManageTab from '@/components/manage/tab'

export default {
  components: { VueCsvImport, ManageTab },
  data() {
    return {
      csvData: [],
      mapFields: {
        uid: "UID",
        email: "メールアドレス",
        employeeCode: "社員番号",
        fullName: "氏名",
        fullNameKana: "ふりがな",
        department: "部署",
        isCandidate: "候補者フラグ",
        isNewcomer: "新人フラグ",
        isManager: "マネージャーフラグ",
        isMeister: "マイスターフラグ",
        isCaptain: "キャプテンフラグ",
        badges: "受賞歴バッジ",
        fileName: "ファイル名",
        status: "ステータス"
      },
      tableColumns: [
        { field: "uid", label: "UID" },
        { field: "email", label: "メールアドレス" },
        { field: "fullName", label: "氏名", width: "100" },
        { field: "fullNameKana", label: "ふりがな", width: "100" },
        { field: "employeeCode", label: "社員番号", width: "100" },
        { field: "department", label: "部署", width: "100" },
        { field: "isCandidate", label: "候補者フラグ", width: "100" },
        { field: "isNewcomer", label: "新人フラグ", width: "100" },
        { field: "isManager", label: "マネージャーフラグ", width: "100" },
        { field: "isMeister", label: "マイスターフラグ", width: "100" },
        { field: "isCaptain", label: "キャプテンフラグ", width: "100" },
        { field: "badges", label: "受賞歴バッジ", width: "100" },
        { field: "fileName", label: "ファイル名", width: "100" },
        { field: "status", label: "ステータス", width: "100" },
      ],
      startIndex: 1,
      endIndex: 100,
      override: false,
      count: 0,
      index: 0,
      progress: 0,
      isProcessing: false
    }
  },
  methods: {
    importData() {
      this.count = this.filteredCsvData.length
      this.index = 0
      this.progress = 0
      this.isProcessing = true

      this.filteredCsvData.forEach((data) => {
        const params = {}

        if (("uid" in data) && data.uid && data.uid.length) {
          // メールアドレス
          if (("email" in data) && data.email) {
            params.email = data.email.trim()
          }

          // 社員番号
          if (("employeeCode" in data) && data.employeeCode) {
            params.employeeCode = data.employeeCode.trim()
          }

          // 氏名
          if (("fullName" in data) && data.fullName) {
            params.fullName = data.fullName.trim()
          }

          // ふりがな
          if (("fullNameKana" in data) && data.fullNameKana) {
            params.fullNameKana = data.fullNameKana.trim()
          }

          // 部署
          if (("department" in data) && data.department) {
            params.department = data.department.trim()
          }

          // 候補者フラグ
          if (("isCandidate" in data) && data.isCandidate) {
            params.isCandidate = (data.isCandidate.trim().toLowerCase() == "true")
          }

          // 新人フラグ
          if (("isNewcomer" in data) && data.isNewcomer) {
            params.isNewcomer = (data.isNewcomer.trim().toLowerCase() == "true")
          }

          // マネージャーフラグ
          if (("isManager" in data) && data.isManager) {
            params.isManager = (data.isManager.trim().toLowerCase() == "true")
          }

          // マイスターフラグ
          if (("isMeister" in data) && data.isMeister) {
            params.isMeister = (data.isMeister.trim().toLowerCase() == "true")
          }

          // キャプテン
          if (("isCaptain" in data) && data.isCaptain) {
            params.isCaptain = (data.isCaptain.trim().toLowerCase() == "true")
          }

          // 受賞歴バッジ
          if ("badges" in data) {
            if (data.badges) params.badges = data.badges.split(";")
            else params.badges = null
          }

          // ファイル名
          if (("fileName" in data) && data.fileName) {
            params.fileName = data.fileName.trim()
          }

          // ステータス
          if (("status" in data) && data.status) {
            params.status = (data.status.trim().toLowerCase() == "true")
          }

          db.collection('users').doc(data.uid).set(params, { merge: true })
          .then(() => {
            console.log("インポート成功:", params)
          })
          .catch(err => {
            console.log("インポート失敗:", params, err)
          })
          .finally(() => {
            this.index++
          })
        } else {
          console.log("UIDがブランクのためスキップ:", data)
          this.index++
        }
      })
    }
  },
  computed: {
    filteredCsvData() {
      if (this.startIndex && this.startIndex > 0 && this.endIndex && this.endIndex > 0) {
        return this.csvData.slice(this.startIndex - 1, this.endIndex)
      } else {
        return []
      }
    }
  },
  watch: {
    index() {
      this.progress = Math.round(this.index / this.count * 100)
      this.isProcessing = (this.index < this.count)
    }
  }
}
</script>


<style lang="scss" scoped>
main {
  height: 100%;
  overflow-y: scroll;
  padding: 1.5rem 2.5rem;
}

::v-deep .vue-csv-uploader-part-one {
  .csv-import-file {
    margin-bottom: 0.5rem;
  }
}
</style>
